import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'

const AppSpendMattersS2CBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;

  .SpendMattersS2CBannerBannerSection {
    height: 40vw;
    display: flex;
    justify-content: space-between; /* Space between text and image */
    align-items: center; /* Vertically center content */
    padding: 0 80px; /* Add padding to the sides */
    background-color: #102c64;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23035484' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23047CC2'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    @media (max-width: 425px) {
      height: 40vh;
    }
  }

  .SpendMattersS2CBannerBannerContentSection {
    flex: 1; /* Take up available space */
    max-width: 60%; /* Limit width of text content */
  }

  .SpendMattersS2CBannerHeading {
    text-align: left;
    margin-top: 55px;
    font-size: 2.2rem;
    color: #f1f1f1;
    @media (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1650px) {
      font-size: 2.2rem;
    }
    @media (max-width: 1090px) {
      font-size: 2rem;
      width: 450px;
    }
    @media (max-width: 770px) {
      width: 330px;
      font-size: 1.5rem;
    }
    @media (max-width: 425px) {
      width: 230px;
      padding-top: 15px;
      font-size: 1rem;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #34a1d5;
    color: white;
    border: none;
    border-radius: 42px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Space between paragraph and button */
    @media (min-width: 1650px) {
      font-size: 1.5rem;
    }
    @media (max-width: 425px) {
      font-size: 0.8rem;
      margin: 0;
    }

    &:hover {
      background-color: #fff;
      color: #111;
    }
  }

  .SpendMattersS2CBannerImage {
    padding-top: 44px;
    flex: 1; /* Take up available space */
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the image */
    @media (max-width: 425px) {
      display: none;
    }

    img {
      max-width: 38%; /* Ensure image doesn't overflow */
      height: auto; /* Maintain aspect ratio */
    }
  }
`

export const AppSpendMattersS2CBanner: React.FC = () => {
  return (
    <AppSpendMattersS2CBannerWapper>
      <div className="SpendMattersS2CBannerBannerSection">
        <div className="SpendMattersS2CBannerBannerContentSection">
          <ReUseHOneTag
            Heading='Spend Matters recognizes Raindrop as the "Customer Favorite" SolutionMap Badge Winner in Spring 2025 for S2C'
            HeadingStyle="SpendMattersS2CBannerHeading"
          />

          <button
            onClick={() => {
              window.open(
                'https://storage.googleapis.com/raindroppublic/website_data/Spring2025-Raindrop-SpendMatters-S2C-Infographic.pdf',
                '_blank'
              )
            }}
          >
            Download Now
          </button>
        </div>
        <div className="SpendMattersS2CBannerImage">
          <img
            src="https://storage.googleapis.com/raindroppublic/website_data/Customer-Favorite-S2C-2025.png"
            alt="Banner Image"
          />
        </div>
      </div>
    </AppSpendMattersS2CBannerWapper>
  )
}
